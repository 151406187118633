@import '../../styles/customMediaQueries.css';

.section {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 32px;
  padding-right: 32px;
}

.sectionContentContainer {
  width: 100%;
  max-width: 1128px;
}
