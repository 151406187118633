@import '../../styles/customMediaQueries.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  background-color: var(--matterColorBright);
  position: relative;

  padding-top: 26px;
  padding-bottom: 26px;

  @media (--viewportMedium) {
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    justify-content: center;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.background {
  background: var(--marketplaceColor);
}

.content {
  color: var(--matterColorLight);
}

.footerColumnWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (--viewportLarge) {
    flex-direction: row;
    margin-bottom: 32px;
  }
}

.footerColumn {
  margin-right: 50px;
}

.footerColumn:last-child {
  margin-right: 0;
}

.responsiveItemOrdering {
  display: flex;
  flex-direction: column-reverse;
  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.hideMobile {
  display: none;
  visibility: hidden;
  @media (--viewportLarge) {
    display: flex;
    visibility: visible;
  }
}

.showMobile {
  display: flex;
  visibility: visible;
  @media (--viewportLarge) {
    display: none;
    visibility: hidden;
  }
}

.mobileLinks {
  display: flex;
  flex-direction: column;
  margin: 18px 0;

  @media (--viewportLarge) {
    display: none;
    visibility: hidden;
  }
}

.mobileChildrenLinks {
  color: var(--matterColorLight);
  font-size: 14px;
}

.link {
  margin-right: 50px;
  color: var(--matterColorLight);
  font-size: 14px;
}

.icon {
  margin-right: 20px;
}
.socialIcons {
  display: flex;
  flex-direction: column;
}

.social {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    align-self: flex-end;
  }
}

.footerIcons {
  margin-bottom: 20px;
  @media (--viewportLarge) {
    margin-bottom: 0px;
  }
}

.socialIcon {
  filter: invert(97%) sepia(93%) saturate(7%) hue-rotate(225deg) brightness(106%) contrast(100%);
}
