#landing-page-hero #ctas {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

#landing-page-hero #ctas a {
  cursor: pointer;
  margin: 0;

  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;

  text-decoration: none;

  border-radius: 2px;

  background-color: transparent;
  color: #ffffff;

  font-weight: bold;
  border: 3px solid #05a5b6;
  display: block;
  text-align: center;
  padding: 15px 0;
  min-height: 0;

  @media (min-width: 768px) {
    display: inline-block;
    width: 240px;
  }
}

#landing-page-hero #ctas a:first-child {
  background-color: #05a5b6;
  color: #ffffff;
  border: 3px solid transparent;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

#landing-page-hero #ctas a:hover,
#landing-page-hero #ctas a:focus {
  background-color: #ffffff;
  color: #05a5b6;
  border: 3px solid #ffffff;
}

#landing-page-hero #ctas a:first-child:hover,
#landing-page-hero #ctas a:first-child:focus {
  background-color: #2b7181;
  color: #ffffff;
  border: 3px solid #2b7181;
}

#landing-page-support-donatedirect #support-donatedirect h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
}

#landing-page-support-donatedirect a {
  cursor: pointer;

  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;

  text-decoration: none;

  border-radius: 2px;

  background-color: rgb(28, 77, 140);
  color: #ffffff;

  font-weight: bold;
  border: 3px solid #ffffff;
  display: block;
  text-align: center;
  padding: 8px 0;
  min-height: 36px;

  width: 100%;

  @media (min-width: 768px) {
    width: 240px;
  }
}

#landing-page-support-donatedirect a:hover,
#landing-page-support-donatedirect a:focus {
  background-color: #ffffff;
  color: rgb(28, 77, 140);
  border: 3px solid #ffffff;
}

#landing-page-how-donatedirect-works {
  text-align: center;
}

#landing-page-what-is-donatedirect #what-is-donatedirect a {
  cursor: pointer;
  margin-top: 30px;

  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;

  text-decoration: none;

  border-radius: 2px;

  background-color: #05a5b6;
  color: #ffffff;

  font-weight: bold;
  border: 3px solid #05a5b6;
  display: block;
  text-align: center;
  padding: 15px 0;
  min-height: 0;
  box-shadow: none;

  @media (min-width: 768px) {
    display: inline-block;
    width: 240px;
  }
}

#landing-page-what-is-donatedirect #what-is-donatedirect a:hover,
#landing-page-what-is-donatedirect #what-is-donatedirect a:focus {
  background-color: #2b7181;
  color: #ffffff;
  border: 3px solid #2b7181;
}

#landing-page-how-donatedirect-works img {
  height: 62px;
  filter: invert(20%) sepia(77%) saturate(1543%) hue-rotate(196deg) brightness(91%) contrast(87%);
}

div#landing-page-browse-by-category img {
  border-radius: 0;
  object-fit: contain;
  height: 25px;
  filter: invert(20%) sepia(77%) saturate(1543%) hue-rotate(196deg) brightness(91%) contrast(87%);
}

#landing-page-acknowledgement-of-country img {
  width: 350px;
  height: 175px;
  padding: 10px;
}

#our-board-content img {
  width: 200px;
  height: 200px;
}

[id^='our-board-'][id$='-image'] {
  height: 250px;
  text-align: center;
  padding-top: 50px;
}

[id^='our-board-'][id$='-text'] h2 {
  line-height: 1;
}

[id^='our-board-'][id$='-text'] {
  padding-top: 50px;
}

[id^='landing-page-our-sponsors'] [class^='SectionContainer_sectionContent__'] {
  padding: 24px 0;
}
