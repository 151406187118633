@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px 0 2px 0;
}

.title {
  /* Font */
  composes: h3 from global;

  color: var(--marketplaceColor);
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}
.subtextWrapper {
  display: inline-flex;
}
.subtext {
  /* Font */
  composes: marketplaceDefaultFontStyles from global;
  line-height: 20px;
  color: var(--matterColor);
  padding-top: 4px;
  font-size: 14px;
}
.subtextBold {
  /* Font */
  composes: marketplaceDefaultFontStyles from global;
  line-height: 20px;
  color: var(--matterColor);
  padding-top: 4px;
  font-weight: var(--fontWeighth3);
  font-size: 14px;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
