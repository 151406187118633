@import '../../../styles/customMediaQueries.css';

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.details {
  padding-bottom: 1px;
  margin-top: 10px;

  @media (--viewportLarge) {
    margin: 0;
  }
}

.importantDetails {
  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
  }
}

.sectionTitle {
  /* Font for sections titles */
  composes: h3 from global;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.11px;
  float: left;
}

.deliveryTitle {
  composes: sectionTitle;
  margin-top: 0;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.detailsTitle {
  font-weight: var(--fontWeightSemiBold);
}

.separator {
  @media (--viewportLarge) {
    display: none;
  }
}

.detailsValue {
  line-height: 24px;
}

.generalRow {
  padding: 0 0 7px 0;
  line-height: 1.2rem;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    line-height: 1.2rem;
  }
}

.deliveryRow {
  padding: 0 0 7px 0;
  line-height: 24px;
  list-style: none;
  font-weight: var(--fontWeightRegular);
}

.detailsRow {
  composes: generalRow;
  line-height: 20px;
}

.deliveryDetails {
  compose: generalRow;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.svgIcon {
  margin-right: 7px;
}

.svgRoot {
  width: 19px;
  height: 12px;
  stroke: var(--marketplaceColor);
}

.deliveryOptions {
  margin-bottom: 0;
}
