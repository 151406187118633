@import '../../styles/customMediaQueries.css';

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  line-height: 16px;
}

.tooltipText::after {
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}
